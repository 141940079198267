import { getFunctions, httpsCallable } from "firebase/functions";
import { log } from "../../helper/helper";
import config from "./../../config.json";
import { getApp } from "firebase/app";

export function adminSendSMS(number, temp) {
  let data = {
    mobile_number: number,
    sms_template_name: temp,
  };

  log(data);
  const functions = getFunctions(getApp(), config.australiaSoutheast);
  const sendPushNotification = httpsCallable(functions, config.adminSendSMS);
  return sendPushNotification(data);
}
