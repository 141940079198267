import { getFunctions, httpsCallable } from "firebase/functions";
import { log } from "../../helper/helper";
import config from "./../../config.json";
import { getApp } from "firebase/app";

export function adminSendPushNotification(
  target,
  title,
  message,
  userId,
  userType
) {
  let data = {};
  if (target === "custom") {
    data = {
      notification_target: target,
      notification_title: title,
      notification_text: message,
      notification_user_id: userId,
      notification_user_type: userType,
    };
  } else {
    data = {
      notification_target: target,
      notification_title: title,
      notification_text: message,
    };
  }
  log(data);
  const functions = getFunctions(getApp(), config.australiaSoutheast);
  const sendPushNotification = httpsCallable(
    functions,
    config.adminSendPushNotification
  );
  return sendPushNotification(data);
}
