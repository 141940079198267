import { getFunctions, httpsCallable } from "firebase/functions";
import { log } from "../../helper/helper";
import config from "./../../config.json";
import { getApp } from "firebase/app";

export function adminGetParcels() {
  const functions = getFunctions(getApp(), config.australiaSoutheast);
  const parcels = httpsCallable(functions, config.adminGetParcels);
  return parcels();
}

export function adminDeleteParcel(uid) {
  const data = {
    parcel_uid: uid,
  };
  log(data);
  const functions = getFunctions(getApp(), config.australiaSoutheast);
  const deleteParcel = httpsCallable(functions, config.adminDeleteParcel);
  return deleteParcel(data);
}


export function adminParcel(
  parcelUid,
  parcelImgUrl,
  description,
  parcelNote,
  maxWeight,
  minWeight,
  parcelType,
  listIndexNumber,
  walking,
  cycling,
  riding,
  isShowInOrder
) {
  let data = {};
  if (parcelUid !== "") {
    data = {
      parcel_description: description,
      change_note: parcelNote,
      parcel_max_weight: Number(maxWeight),
      parcel_min_weight: Number(minWeight),
      parcel_type: parcelType,
      list_index_on_the_apps: Number(listIndexNumber),
      vehicle_type_walking: walking,
      vehicle_type_cycling: cycling,
      vehicle_type_riding: riding,
      parcel_uid: parcelUid,
      parcel_img_url: parcelImgUrl,
      is_shown_for_orders: isShowInOrder,
    };
  } else {
    data = {
      parcel_description: description,
      change_note: parcelNote,
      parcel_max_weight: Number(maxWeight),
      parcel_min_weight: Number(minWeight),
      parcel_type: parcelType,
      list_index_on_the_apps: Number(listIndexNumber),
      vehicle_type_walking: walking,
      vehicle_type_cycling: cycling,
      vehicle_type_riding: riding,
      is_shown_for_orders: isShowInOrder,
    };
  }
  log(data);
  const functions = getFunctions(getApp(), config.australiaSoutheast);
  if (parcelUid !== "") {
    const adminUpdate = httpsCallable(functions, config.adminUpdateParcel);
    return adminUpdate(data);
  } else {
    const parcel = httpsCallable(functions, config.adminAddParcel);
    return parcel(data);
  }
}
