import { getFunctions, httpsCallable } from "firebase/functions";
import config from "./../../config.json";
import { getApp } from "firebase/app";

export function adminRiderInbox() {
  const functions = getFunctions(getApp(), config.australiaSoutheast);
  const adminRider = httpsCallable(
    functions,
    config.adminRiderInbox
  );
  return adminRider();
}
