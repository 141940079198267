import { getFunctions, httpsCallable } from "firebase/functions";
import { log } from "../../helper/helper";
import config from "./../../config.json";
import { getApp } from "firebase/app";


export function adminSendEmail(bearerType, email, temp, title, message, industryName) {
  var data = {};
  if (bearerType === "Rider") {
    data = {
      email_address: email,
      email_template_name: temp,
    };
  } else if (temp === "business-customer-industry-surveys") {
    data = {
      email_address: email,
      email_template_name: temp,
      recipients_title: title,
      industry: industryName,

    };
  } else {
    data = {
      email_address: email,
      email_template_name: temp,
      recipients_title: title,
      customised_introduction_text: message,
    };
  }

  log(data);
  const functions = getFunctions(getApp(), config.australiaSoutheast);
  const sendPushNotification = httpsCallable(functions, config.adminSendEmail);
  return sendPushNotification(data);
}
