import { getFunctions, httpsCallable } from "firebase/functions";
import { log } from "../../helper/helper";
import config from "./../../config.json";
import { getApp } from "firebase/app";


export function adminAllRidersChangeBounesPercentage(bonusFees, reason) {
    let data = {
        riders_bonus_on_fees: bonusFees,
        riders_bonus_reason_description: reason
    };
    log(data);
    const functions = getFunctions(getApp(), config.australiaSoutheast);
    const changeBounes = httpsCallable(functions, config.adminAllRidersChangeBounesPercentage);
    return changeBounes(data);
}