import { getFunctions, httpsCallable } from "firebase/functions";
import { log } from "../../helper/helper";
import config from "./../../config.json";
import { getApp } from "firebase/app";


export function adminFindRider(inputType, inputValue) {
  const data = { type: inputType, value: inputValue };
  const functions = getFunctions(getApp(), config.australiaSoutheast);
  const findRider = httpsCallable(functions, config.adminFindRider);
  log(data);
  return findRider(data);
}

export function adminOnboardingChangeMotorbikeDeletes(uid, count) {
  const data = { uid: uid, mb_deletes_during_onboarding: Number(count) };
  const functions = getFunctions(getApp(), config.australiaSoutheast);

  const changeMotorbikeDelete = httpsCallable(
    functions,
    config.adminOnboardingChangeMotorbikeDeletes
  );
  log(data);
  return changeMotorbikeDelete(data);
}

export function adminOnboardingDaleteStage(uid, count) {
  const data = { uid: uid, sign_up_stage: count };
  const functions = getFunctions(getApp(), config.australiaSoutheast);

  const onboardingDaleteStage = httpsCallable(
    functions,
    config.adminOnboardingDaleteStage
  );
  log(data);
  return onboardingDaleteStage(data);
}

export function adminRiderGetOnlineTime(uid, date) {
  const data = { uid: uid, date: date };
  const functions = getFunctions(getApp(), config.australiaSoutheast);

  const onboardingDaleteStage = httpsCallable(
    functions,
    config.adminRiderGetOnlineTime
  );
  log(data);
  return onboardingDaleteStage(data);
}

export function adminRiderAddNote(uid, title, note, probabilitySelected) {
  const data = {
    uid: uid,
    note_title: title,
    note_text: note,
    note_user_engagement_scale: probabilitySelected,
  };
  const functions = getFunctions(getApp(), config.australiaSoutheast);

  const riderAddNote = httpsCallable(functions, config.adminRiderAddNote);
  log(data);
  return riderAddNote(data);
}

export function adminRiderChangeIsOnJob(uid, isOnJob) {
  const data = { uid: uid, is_on_job: isOnJob };
  const functions = getFunctions(getApp(), config.australiaSoutheast);

  const riderChangeIsOnJob = httpsCallable(
    functions,
    config.adminRiderChangeIsOnJob
  );
  log(data);
  return riderChangeIsOnJob(data);
}

export function adminRiderChangeAllow(uid, status, reason) {
  let data = {};
  if (reason !== "") {
    data = {
      uid: uid,
      is_user_allowed_by_bearer: status,
      not_allowed_reason: reason,
    };
  } else {
    data = { uid: uid, is_user_allowed_by_bearer: status };
  }
  const functions = getFunctions(getApp(), config.australiaSoutheast);

  const changeAllow = httpsCallable(functions, config.adminRiderChangeAllow);
  log(data);
  return changeAllow(data);
}

export function adminRiderChangeBonusPercentage(uid, bonus, info) {
  const data = {
    uid: uid,
    riders_bonus_on_fees: bonus,
    riders_bonus_reason_description: info,
  };
  const functions = getFunctions(getApp(), config.australiaSoutheast);

  const bonusPercentage = httpsCallable(
    functions,
    config.adminRiderChangeBonusPercentage
  );
  log(data);
  return bonusPercentage(data);
}

export function adminOnboardingChangeAllow(uid, status, reason) {
  let data = {};
  if (reason !== "") {
    data = {
      uid: uid,
      user_access_is_alowed_to_onboarding: status,
      not_allowed_to_access_onboarding_reason: reason,
    };
  } else {
    data = { uid: uid, user_access_is_alowed_to_onboarding: status };
  }
  const functions = getFunctions(getApp(), config.australiaSoutheast);

  const changeAllow = httpsCallable(
    functions,
    config.adminOnboardingChangeAllow
  );
  log(data);
  return changeAllow(data);
}

export function adminRiderDocumentReviewReject(
  uid,
  note,
  pageId,
  insuranceInvalidityReason
) {
  const data = {
    uid: uid,
    result_note: note,
    page_id: pageId,
    public_liability_insurance_invalidity_reason: insuranceInvalidityReason,
  };
  const functions = getFunctions(getApp(), config.australiaSoutheast);

  const documentReviewReject = httpsCallable(
    functions,
    config.adminRiderDocumentReviewReject
  );
  log(data);
  return documentReviewReject(data);
}

export function adminRiderDocumentReviewFurtherInvestigation(
  uid,
  note,
  pageId,
  insuranceInvalidityReason
) {
  const data = {
    uid: uid,
    result_note: note,
    page_id: pageId,
    public_liability_insurance_invalidity_reason: insuranceInvalidityReason,
  };
  const functions = getFunctions(getApp(), config.australiaSoutheast);

  const documentReviewInvestigation = httpsCallable(
    functions,
    config.adminRiderDocumentReviewFurtherInvestigation
  );
  log(data);
  return documentReviewInvestigation(data);
}

export function adminRiderDocumentReviewVerifyLegalDetails(
  uid,
  legalName,
  policyNumber,
  insuranceDate
) {
  const data = {
    uid: uid,
    legal_name: legalName,
    policy_number: policyNumber,
    public_liability_insurance_validity_date: insuranceDate,
  };
  const functions = getFunctions(getApp(), config.australiaSoutheast);

  const documentVerifyLegalDetails = httpsCallable(
    functions,
    config.adminRiderDocumentReviewVerifyLegalDetails
  );
  log(data);
  return documentVerifyLegalDetails(data);
}

export function adminRiderDocumentReviewVerifyRightToWork(
  uid,
  certificateDate,
  fullName,
  birthDate,
  isCitizen,
  visaGrantDate,
  unlimitedWorkRight,
  visaExpiryDate,
  allowedHoursWeek,
  visaSubclass,
  visaConditionsNote
) {
  let data = {
    uid: uid,
    date_of_certificate: certificateDate, //YYYY-MM-DD
    full_name: fullName,
    birth_date: birthDate, //YYYY-MM-DD
    is_australian_citizen: isCitizen,
  };
  if (visaGrantDate !== "") {
    data = { ...data, visa_grant_date: visaGrantDate };
  }
  if (!isCitizen) {
    data = { ...data, is_issued_with_unlimited_work_right: unlimitedWorkRight };
  }
  if (visaExpiryDate !== "") {
    data = { ...data, visa_expiry_date: visaExpiryDate };
  }
  if (allowedHoursWeek !== "") {
    data = {
      ...data,
      allowed_working_hours_per_week: Number(allowedHoursWeek),
    };
  }
  if (visaSubclass !== "") {
    data = { ...data, visa_subclass: visaSubclass };
  }

  if (visaConditionsNote !== "") {
    data = { ...data, visa_conditions_note: visaConditionsNote };
  }

  log(data);
  const functions = getFunctions(getApp(), config.australiaSoutheast);

  const reviewVerifyRightToWork = httpsCallable(
    functions,
    config.adminRiderDocumentReviewVerifyRightToWork
  );
  log(data);
  return reviewVerifyRightToWork(data);
}

export function adminRiderDocumentReviewVerifyPoliceCheck(
  uid,
  certificateDate,
  fullName,
  birthDate,
  gender
) {
  let data = {
    uid: uid,
    date_of_certificate: certificateDate, //YYYY-MM-DD
    full_name: fullName,
    birth_date: birthDate, //YYYY-MM-DD
    gender: gender, //"Male" || "Female"
  };
  const functions = getFunctions(getApp(), config.australiaSoutheast);

  const policeCheck = httpsCallable(
    functions,
    config.adminRiderDocumentReviewVerifyPoliceCheck
  );
  log(data);
  return policeCheck(data);
}

export function adminRiderDocumentReviewAvatarAndPhotoID(uid, name) {
  const data = { uid: uid, full_name: name };
  const functions = getFunctions(getApp(), config.australiaSoutheast);

  const reviewAvatarAndPhotoID = httpsCallable(
    functions,
    config.adminRiderDocumentReviewAvatarAndPhotoID
  );
  log(data);
  return reviewAvatarAndPhotoID(data);
}

export function adminRiderResetLatestActivity(uid) {
  const data = { uid: uid };
  const functions = getFunctions(getApp(), config.australiaSoutheast);

  const resetLatestActivity = httpsCallable(
    functions,
    config.adminRiderResetLatestActivity
  );
  log(data);
  return resetLatestActivity(data);
}

export function adminGetMotorbikeInfo(uid) {
  const data = { motorbike_id: uid };
  const functions = getFunctions(getApp(), config.australiaSoutheast);

  const motorbikeInfo = httpsCallable(functions, config.adminGetMotorbikeInfo);
  log(data);
  return motorbikeInfo(data);
}

export function adminGetRiderInfo(riderId) {
  const data = { rider_user_id: riderId };
  const functions = getFunctions(getApp(), config.australiaSoutheast);

  log(data);
  const findTransport = httpsCallable(functions, config.adminGetRiderInfo);
  return findTransport(data);
}

export function adminRiderLocationRefresh(riderId) {
  const data = { rider_user_id: riderId };
  const functions = getFunctions(getApp(), config.australiaSoutheast);

  log(data);
  const findTLocation = httpsCallable(functions, config.adminGetRiderLocation);
  return findTLocation(data);
}
