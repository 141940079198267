import { getFunctions, httpsCallable } from "firebase/functions";
import { log } from "../../helper/helper";
import config from "./../../config.json";
import { getApp } from "firebase/app";

export function adminGetPricingObject() {
  const functions = getFunctions(getApp(), config.australiaSoutheast);
  const pricingObject = httpsCallable(functions, config.adminGetPricingObject);
  return pricingObject();
}

export function adminUpdatePricingObject(
  segmentOne,
  segmentTwo,
  segmentThree,
  segmentFour,
  walkingBase,
  cyclingBase,
  ridingBase,
  dropOffTime,
  pickUpTime,
  specialCondition,
  wage,
  segmentTimeOne,
  segmentTimeTwo,
  segmentTimeThree,
  changeNote,
  specialConditionNote
) {
  const data = {
    charges_on_distance_segment_one: Number(segmentOne),
    charges_on_distance_segment_two: Number(segmentTwo),
    charges_on_distance_segment_three: Number(segmentThree),
    charges_on_distance_segment_four: Number(segmentFour),
    cycling_base_fare: Number(cyclingBase),
    drop_off_time: Number(dropOffTime),
    pick_up_time: Number(pickUpTime),
    riding_base_fare: Number(ridingBase),
    special_condition: Number(specialCondition),
    time_of_the_day_segment_one: Number(segmentTimeOne),
    time_of_the_day_segment_two: Number(segmentTimeTwo),
    time_of_the_day_segment_three: Number(segmentTimeThree),
    wage: Number(wage),
    walking_base_fare: Number(walkingBase),
    change_note: changeNote,
    special_condition_note: specialConditionNote,
  };
  const functions = getFunctions(getApp(), config.australiaSoutheast);
  const pricingObject = httpsCallable(
    functions,
    config.adminUpdatePricingObject
  );
  log(data);
  return pricingObject(data);
}
