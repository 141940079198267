import { getFunctions, httpsCallable } from "firebase/functions";
import { log } from "../../helper/helper";
import config from "./../../config.json";
import { getApp } from "firebase/app";


export function adminShowAllAssignmentTransports(cursorTransportId) {
    let data = {};
    if (cursorTransportId !== "") {
        data = {
            cursor_transport_id: cursorTransportId,
        };
    }
    log(data);
    const functions = getFunctions(getApp(), config.australiaSoutheast);
    const allTransports = httpsCallable(functions, config.adminShowAllAssignmentTransports);
    return allTransports(data);
}