import { getFunctions, httpsCallable } from "firebase/functions";
import { log } from "../../helper/helper";
import config from "./../../config.json";
import { getApp } from "firebase/app";

export function getAdminWebsiteMessageInbox() {
  const functions = getFunctions(getApp(), config.australiaSoutheast);
  const adminWebsiteMessageInbox = httpsCallable(
    functions,
    config.adminWebsiteMessageInbox
  );
  return adminWebsiteMessageInbox();
}

export function getAdminInfo() {
  const functions = getFunctions(getApp(), config.australiaSoutheast);
  const adminGetAdminInfo = httpsCallable(functions, config.adminGetAdminInfo);
  return adminGetAdminInfo();
}

export function adminWebsiteMessageChange(
  messageUid,
  messageType,
  isRead,
  messageCaseStatus,
  messagePriority,
  messageCategory,
  messageSubCategory,
  adminNoteTitle,
  adminNoteText,
  assignedToNewAdminUid,
  newAssignmentReason
) {
  const data = {
    message_uid: messageUid,
    message_type: messageType,
    is_read: isRead,
    message_case_status: messageCaseStatus,
    message_priority: messagePriority,
    message_category: messageCategory,
    message_sub_category: messageSubCategory,
    admin_note_title: adminNoteTitle,
    admin_note_text: adminNoteText,
    assigned_to_new_admin_uid: assignedToNewAdminUid,
    new_assignment_reason: newAssignmentReason,
  };
  const functions = getFunctions(getApp(), config.australiaSoutheast);
  const adminWebsiteMessage = httpsCallable(
    functions,
    config.adminWebsiteMessageChange
  );
  return adminWebsiteMessage(data);
}
