import { getFunctions, httpsCallable } from "firebase/functions";
import config from "./../../config.json";
import { log } from "../../helper/helper";
import { getApp } from "firebase/app";

export function getAdminOnboardingInbox(
  signupStage,
  isQueryByDate,
  startDate,
  endDate
) {
  const data = {
    sign_up_stage: Number(signupStage),
    is_query_by_date: isQueryByDate,
    start_date: startDate,
    end_date: endDate,
  };
  const functions = getFunctions(getApp(), config.australiaSoutheast);
  const adminRider = httpsCallable(functions, config.adminOnboardingInbox);
  log(data);
  return adminRider(data);
}
