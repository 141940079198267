import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getApp } from "firebase/app";
import config from "./../../config.json";

export function loginInFirebase(username, password) {
  const auth = getAuth();
  return signInWithEmailAndPassword(auth, username, password);
}

export function getMobileNumberInLogin() {
  const functions = getFunctions(getApp(), config.australiaSoutheast);
  const getPhoneNumber = httpsCallable(functions, config.adminGetMobileNumber);
  return getPhoneNumber();
}

export function adminSendSMS() {

  const functions = getFunctions(getApp(), config.australiaSoutheast);
  const confirmMobile = httpsCallable(functions, config.adminLoginSendSMS);
  return confirmMobile();
}

export function adminConfirmSMS(smsCode) {
  const functions = getFunctions(getApp(), config.australiaSoutheast);
  const confirmSMSCode = httpsCallable(functions, config.adminConfirmSMS);
  return confirmSMSCode({ token: smsCode });
}

export function adminLoginPermission() {
  const functions = getFunctions(getApp(), config.australiaSoutheast);
  const permission = httpsCallable(functions, config.adminLoginPermission);
  return permission();
}
