import { getFunctions, httpsCallable } from "firebase/functions";
import config from "./../../config.json";
import { Timestamp } from "firebase/firestore";
import { log } from "../../helper/helper";
import { getApp } from "firebase/app";

export function adminShowAllCoupons(cursorTransportId) {
  let data = {};
  if (cursorTransportId !== "") {
    data = {
      cursor_transport_id: cursorTransportId,
    };
  }
  log(data);
  const functions = getFunctions(getApp(), config.australiaSoutheast);
  const findCoupon = httpsCallable(functions, config.adminShowAllCoupons);
  return findCoupon(data);
}

export function adminCreateNewCoupon(
  couponUid = "",
  nameTag,
  maxOveralRedemptions,
  maxOveralRedemptionsperCustomer,
  minOrderAmount,
  maxAmountOff,
  percentOff,
  description,
  changeNote,
  marketingDescriptionAdmin,
  userType,
  couponType,
  allowedUserId,
  duration,
  crmStatus,
  loyaltyTier,
  onBirthday,
  onSignupDay,
  stillValidByBearer,
  selectedDate,
  isFlatRateCoupon
) {
  const dateTime = new Date(selectedDate);
  let noteDate = Timestamp.fromDate(dateTime);
  let nanoSecond = noteDate.seconds * 1000;
  var data = {}
  if (isFlatRateCoupon === true) {
    data = {
      coupon_type: couponType,
      allowed_user_id: allowedUserId,
      allowed_user_type: userType,
      name_tag: nameTag,
      description: description,
      change_note: changeNote,
      marketing_description_admin: marketingDescriptionAdmin,
      duration: duration,
      duration_validity: nanoSecond,
      max_redemptions_per_customer: Number(maxOveralRedemptionsperCustomer),
      max_overal_redemptions: Number(maxOveralRedemptions),
      preliminary_condition_crm_status: crmStatus,
      preliminary_condition_loyalty_tier: loyaltyTier,
      preliminary_condition_on_birthday: Boolean(onBirthday),
      preliminary_condition_on_signup_day: Boolean(onSignupDay),
      flat_rate_amount: Number(percentOff),
      maximum_original_price_accepted_for_flat_rate: Number(maxAmountOff),
      min_order_amount: Number(minOrderAmount),
      still_valid_by_bearer: Boolean(stillValidByBearer),
      is_flat_rate_coupon: isFlatRateCoupon
    };
  } else {

    data = {
      coupon_type: couponType,
      allowed_user_id: allowedUserId,
      allowed_user_type: userType,
      name_tag: nameTag,
      description: description,
      change_note: changeNote,
      marketing_description_admin: marketingDescriptionAdmin,
      duration: duration,
      duration_validity: nanoSecond,
      max_redemptions_per_customer: Number(maxOveralRedemptionsperCustomer),
      max_overal_redemptions: Number(maxOveralRedemptions),
      preliminary_condition_crm_status: crmStatus,
      preliminary_condition_loyalty_tier: loyaltyTier,
      preliminary_condition_on_birthday: Boolean(onBirthday),
      preliminary_condition_on_signup_day: Boolean(onSignupDay),
      percent_off: Number(percentOff),
      max_amount_off: Number(maxAmountOff),
      min_order_amount: Number(minOrderAmount),
      still_valid_by_bearer: Boolean(stillValidByBearer),
      is_flat_rate_coupon: isFlatRateCoupon
    };
  }

  log(data);
  const functions = getFunctions(getApp(), config.australiaSoutheast);
  const createNew = httpsCallable(functions, config.adminCreateNewCoupon);
  return createNew(data);
}

export function adminUpdateCoupon(
  couponUid,
  nameTag,
  maxOveralRedemptions,
  maxOveralRedemptionsperCustomer,
  minOrderAmount,
  maxAmountOff,
  percentOff,
  description,
  changeNote,
  marketingDescriptionAdmin,
  userType,
  couponType,
  allowedUserId,
  duration,
  crmStatus,
  loyaltyTier,
  onBirthday,
  onSignupDay,
  stillValidByBearer,
  selectedDate,
  isFlatRateCoupon
) {
  const dateTime = new Date(selectedDate);
  let noteDate = Timestamp.fromDate(dateTime);
  let nanoSecond = noteDate.seconds * 1000;
  var data = {}
  if (isFlatRateCoupon === true) {
    data = {
      coupon_uid: couponUid,
      coupon_type: couponType,
      allowed_user_id: allowedUserId,
      allowed_user_type: userType,
      name_tag: nameTag,
      description: description,
      change_note: changeNote,
      marketing_description_admin: marketingDescriptionAdmin,
      duration: duration,
      duration_validity: nanoSecond,
      max_redemptions_per_customer: Number(maxOveralRedemptionsperCustomer),
      max_overal_redemptions: Number(maxOveralRedemptions),
      preliminary_condition_crm_status: crmStatus,
      preliminary_condition_loyalty_tier: loyaltyTier,
      preliminary_condition_on_birthday: Boolean(onBirthday),
      preliminary_condition_on_signup_day: Boolean(onSignupDay),
      flat_rate_amount: Number(percentOff),
      maximum_original_price_accepted_for_flat_rate: Number(maxAmountOff),
      min_order_amount: Number(minOrderAmount),
      still_valid_by_bearer: Boolean(stillValidByBearer),
      is_flat_rate_coupon: isFlatRateCoupon
    };
  } else {

    data = {
      coupon_uid: couponUid,
      coupon_type: couponType,
      allowed_user_id: allowedUserId,
      allowed_user_type: userType,
      name_tag: nameTag,
      description: description,
      change_note: changeNote,
      marketing_description_admin: marketingDescriptionAdmin,
      duration: duration,
      duration_validity: nanoSecond,
      max_redemptions_per_customer: Number(maxOveralRedemptionsperCustomer),
      max_overal_redemptions: Number(maxOveralRedemptions),
      preliminary_condition_crm_status: crmStatus,
      preliminary_condition_loyalty_tier: loyaltyTier,
      preliminary_condition_on_birthday: Boolean(onBirthday),
      preliminary_condition_on_signup_day: Boolean(onSignupDay),
      percent_off: Number(percentOff),
      max_amount_off: Number(maxAmountOff),
      min_order_amount: Number(minOrderAmount),
      still_valid_by_bearer: Boolean(stillValidByBearer),
      is_flat_rate_coupon: isFlatRateCoupon
    };
  }

  log(data);
  const functions = getFunctions(getApp(), config.australiaSoutheast);
  const updateC = httpsCallable(functions, config.adminUpdateCoupon);
  return updateC(data);
}
